import { useLoaderData } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import PlentyBody from '../typography/plenty-body'
import SearchBrandHStack from './search-brand-hstack'
import SearchInformationCard from './search-infomation-card'
import SearchMarketingCard from './search-marketing-card'

type ImageDetails = {
	id: number
	alt: string
	name: string
	focus: string
	title: string
}

type Column = {
	component: string
	_uid: string
	body: string
	image: ImageDetails
	heading: string
	topBody: string
	linkToPage: string
	textColor: string
}

type Content = {
	body: any[] // Since this doesn't seem relevant for rendering, type as any or further specify if needed
	columns: Column[]
}

type Story = {
	content: Content
}

type StoriesData = {
	stories: Story[]
}

type LoaderData = {
	searchData: {
		data: StoriesData
	}
}

const SearchTrendingContent = () => {
	const { searchData } = useLoaderData<LoaderData>()
	const story = searchData.data.stories[1]?.content.body ?? [] // Accessing the first story's body array
	let { t } = useTranslation('search')
	return (
		<div className="space-y-3 bg-white">
			<PlentyBody size="md" className="text-beige-100">
				{t('search_trending')}
			</PlentyBody>
			{story.map((bodyItem: any) => {
				if (bodyItem.component === 'gridColumns') {
					return (
						<div
							key={bodyItem._uid}
							className="grid grid-cols-2 gap-4 overflow-x-auto scrollbar-hide md:grid-cols-4"
							onScroll={e => e.stopPropagation()}
						>
							{bodyItem.columns
								.filter((column: Column, index: number) => {
									// Only show the first 2 searchMarketingCard on mobile
									if (
										window.innerWidth < 768 &&
										column.component === 'searchMarketingCard' &&
										index >= 2
									) {
										return false
									}
									// Hide searchInformationCard on mobile
									if (
										window.innerWidth < 768 &&
										column.component === 'searchInformationCard'
									) {
										return false
									}
									return true
								})
								.map((column: Column, index: number) =>
									column.component === 'searchMarketingCard' ? (
										<div
											key={column._uid}
											className={`min-w-[40%] shrink-0 md:min-w-[25%]`}
										>
											<SearchMarketingCard
												key={column._uid}
												blok={column}
												_uid={''}
												component={'searchMarketingCard'}
											/>
										</div>
									) : (
										<div
											key={column._uid}
											// Hide searchInformationCard on mobile, show on desktop
											className="hidden min-w-[40%] shrink-0 md:block md:min-w-[25%]"
										>
											<SearchInformationCard
												key={column._uid}
												blok={column}
												_uid={''}
												component={'searchInformationCard'}
											/>
										</div>
									),
								)}
						</div>
					)
				} else if (bodyItem.component === 'searchBrandHStack') {
					return (
						<div key={bodyItem._uid} className="hidden md:block">
							<SearchBrandHStack
								blok={bodyItem}
								_uid={bodyItem._uid}
								component={'searchBrandHStack'}
							/>
						</div>
					)
				}
				return null
			})}
		</div>
	)
}

export default SearchTrendingContent
